import React from "react";
import { Button, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createDomain,
  createDomainModal,
} from "../../store/actions/domainActions";

import { toggleDomainControl } from "../../store/actions/configActions";
import LCButton from "../styles/LCButton";

const AddDomain = (props) => {
  const dispatcher = useDispatch();
  const isLoading = useSelector((state) => state.config.disableButton);
  const domainModal = useSelector((state) => state.domains.modal);
  const domains = useSelector((state) => state.domains.domains);
  const [addNewDomainModal] = Form.useForm();
  const currentUser = useSelector((state) => state.auth.user);

  const onFinish = (values) => {
    const domainName = values.domainName.replace("www.", "");
    dispatcher(createDomain(domainName));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const openAddDomainModal = () => {
    dispatcher(toggleDomainControl(false));
    dispatcher(createDomainModal(true));
  };

  return (
    <>
      {currentUser && currentUser.domainsLeft > 0 ? (
        <>
          <div style={{ textAlign: "right", padding: "0px 10px" }}>
            <LCButton
              type="secondary"
              as="button"
              onClick={() => openAddDomainModal()}
              addMargin
            >
              Add Domain
            </LCButton>
          </div>
          <Modal
            title="Add New Domain"
            visible={domainModal}
            onOk={() => addNewDomainModal.submit()}
            onCancel={() => dispatcher(createDomainModal(false))}
            keyboard={false}
            maskClosable={false}
            okText="Add"
            okButtonProps={{
              loading: isLoading,
            }}
            cancelButtonProps={{
              loading: isLoading,
            }}
            footer={[
              <LCButton
                type="primary"
                as="button"
                onClick={() => dispatcher(createDomainModal(false))}
                addMargin
              >
                Cancel
              </LCButton>,
              <LCButton
                type="secondary"
                as="button"
                onClick={() => addNewDomainModal.submit()}
                addMargin
              >
                Save
              </LCButton>,
            ]}
          >
            <Form
              form={addNewDomainModal}
              name="addModalForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="domainName"
                label="Domain Name"
                rules={[
                  {
                    required: true,
                    message: "Please a valid domain name is required.",
                  },
                  {
                    pattern:
                      /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/g,
                    message: "Domain format is not correct.",
                  },
                ]}
              >
                <Input placeholder="Please enter domain name eg., (google.com)" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default AddDomain;
