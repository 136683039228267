import {
  SET_PLANS,
  SET_ANNUAL_CHECK
} from "../actions/types";

const initialState = {
  annual: false,
  plans: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANS:
      return {
        ...state,
        plans: action.payload
      };
    case SET_ANNUAL_CHECK:
      return {
        ...state,
        annual: action.payload
      };
    default:
      return {
        ...state
      };
  }
};