import React from "react";
import { useSelector } from "react-redux";

const AllowedForRole = (props) => {
  const currentUser = useSelector((state) => state.auth.user);

  return (
    <>
      { (currentUser && props.roles.includes(currentUser.role) )  && props.children  }
    </>
  )
}

export default AllowedForRole;