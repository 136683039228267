import {
  SET_ALL_ACTIVITIES,
  SET_ACTIVITY,
  SET_ACTIVITIES_SEARCH
} from "../actions/types";

const initialState = {
  activity: null,
  activities: [],
  prevPage: 1,
  nextPage: 1,
  totalPages: 1,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  totalDocs: 0,
  limit: 0,
  search: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_ACTIVITIES:
      const {
        activities, prevPage, nextPage, totalPages, page, hasNextPage, hasPrevPage, totalDocs, limit
      } = action.payload;
      return {
        ...state,
        activities: activities,
          prevPage: prevPage,
          nextPage: nextPage,
          totalPages: totalPages,
          currentPage: page,
          hasNextPage: hasNextPage,
          hasPrevPage: hasPrevPage,
          totalDocs: totalDocs,
          limit: limit
      };
    case SET_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
      };
    case SET_ACTIVITIES_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};