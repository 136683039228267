import styled from "styled-components";

const GeneralStyles = styled.div`
  .link {
    &:hover {
      background-color: #e6f6ff;
    }
  }

  .link::after {
    border-color: #f3452e;
  }

  .sessionsSubLink {
    margin-left: 16px;
  }

  .sessionsSubLink::after {
    right: 16px;
  }
`;

export default GeneralStyles;
