import axios from "axios";
import { cloneDeep, findIndex, uniq } from "lodash";
import {
  SET_DOMAINS,
  SET_CURRENT_DOMAIN,
  SET_CREATE_DOMAIN_MODAL,
} from "./types";
import { toggleDisableButton } from "./configActions";
import { displayNotification } from "./notificationActions";
import { setDomainCookie } from "./authActions";

export const reteriveAllDomains = () => (dispatch) => {
  axios
    .get(`/domains`)
    .then((response) => {
      const domains = response.data.domains;
      dispatch(setDomains(domains));
    })
    .catch((error) => {
      dispatch(
        displayNotification({
          type: "error",
          message: "Domains can not be fetched.",
        })
      );
      dispatch(toggleDisableButton(false));
    });
};

export const setDomains = (domains) => (dispatch) => {
  dispatch({
    type: SET_DOMAINS,
    payload: domains,
  });
};

export const setCurrentDomain = (domain) => (dispatch) => {
  localStorage.setItem("currentDomain", JSON.stringify(domain));
  dispatch({
    type: SET_CURRENT_DOMAIN,
    payload: domain,
  });
};

export const setCurrentDomainFromLocalStorage = () => (dispatch) => {
  const domain = localStorage.getItem("currentDomain", JSON.stringify(domain));
  if (domain) {
    dispatch(setCurrentDomain(JSON.parse(domain)));
  }
};

export const createDomainModal = (modalState) => (dispatch) => {
  dispatch({
    type: SET_CREATE_DOMAIN_MODAL,
    payload: modalState,
  });
};

export const updateTags = (domains, domainId, tags) => (dispatch) => {
  let domainTags = cloneDeep(domains);
  const currentDomainIndex = findIndex(domainTags, (domain) => {
    return domain._id === domainId;
  });
  domainTags[currentDomainIndex].tags = uniq(
    domainTags[currentDomainIndex].tags.concat(tags)
  );
  dispatch(setDomains(domainTags));
};

export const createDomain = (domainName) => (dispatch) => {
  dispatch(toggleDisableButton(true));
  axios
    .post(`/domains`, {
      name: domainName,
    })
    .then((response) => {
      dispatch(toggleDisableButton(false));
      dispatch(setDomainCookie(response.data.domains));
      dispatch(createDomainModal(false));
      dispatch(
        displayNotification({
          type: "success",
          message: "Domain successfully created.",
        })
      );
      dispatch(
        setCurrentDomain(
          response.data.domains[response.data.domains.length - 1]
        )
      );
    })
    .catch((error) => {
      let errorMsg = error.response.data.error;
      if (errorMsg === "Duplicate name entered.") {
        errorMsg = "Domain name is already taken.";
      }
      dispatch(
        displayNotification({
          type: "error",
          message: errorMsg,
        })
      );
      dispatch(toggleDisableButton(false));
    });
};

export const removeDomain =
  (domainId, currentDomain, closeModal, archive) => (dispatch) => {
    dispatch(toggleDisableButton(true));
    axios
      .delete(`/domains/${domainId}`, {
        data: {
          archive: archive,
        },
      })
      .then((response) => {
        dispatch(toggleDisableButton(false));
        dispatch(setDomainCookie(response.data.domains));
        closeModal(false);
        dispatch(
          displayNotification({
            type: "success",
            message: "Domain successfully removed.",
          })
        );
        if (currentDomain && currentDomain._id === domainId) {
          dispatch(setCurrentDomain(null));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          dispatch(
            displayNotification({
              type: "error",
              message: "You are not the owner of the domain.",
            })
          );
        } else {
          dispatch(
            displayNotification({
              type: "error",
              message: "Domain can not be removed, please try later.",
            })
          );
        }

        dispatch(toggleDisableButton(false));
      });
  };

export const updateDomain =
  (currentDomain, formData, closeModal) => (dispatch) => {
    dispatch(toggleDisableButton(true));
    axios
      .put(`/domains/${currentDomain._id}`, formData)
      .then((response) => {
        dispatch(toggleDisableButton(false));
        dispatch(setDomainCookie(response.data.domains));
        closeModal(false);
        dispatch(
          displayNotification({
            type: "success",
            message: "Settings successfully updated.",
          })
        );
      })
      .catch((error) => {
        dispatch(
          displayNotification({
            type: "error",
            message: "Activity settings can not be removed, please try later.",
          })
        );
        dispatch(toggleDisableButton(false));
      });
  };
