import React from "react";
import { useSelector } from "react-redux";
import { DomainTwoTone } from "@material-ui/icons";
const Favicon = (props) => {
  let currentDomain = useSelector((state) => state.domains.currentDomain);

  if (props.domain) {
    currentDomain = props.domain;
  }

  return (
    <>
      {currentDomain &&
        (currentDomain.name === "localhost" ? (
          <DomainTwoTone style={{ fontSize: "26px", color: "#002f53" }} />
        ) : (
          <img
            src={`https://www.google.com/s2/favicons?sz=24&domain_url=${currentDomain.name}`}
            alt="favicon"
            width="24"
          />
        ))}
    </>
  );
};

export default Favicon;
