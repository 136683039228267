import styled from "styled-components";

const NotificationItemStyles = styled.div`
  .notificationContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
  }

  .notificationInfo {
    display: flex;
    align-items: center;
  }

  .notificationIcon {
    height: 30px;
    width: 30px;
    background: #002f53;
    color: white;
    border-radius: 8px;
    padding: 3px 4px;
    margin-right: 10px;
  }

  .notificationText {
    color: #7b7b7b;
    margin-bottom: 0px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .notificationTitle {
    font-weight: 600;
  }
`;

export default NotificationItemStyles;
