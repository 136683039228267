import React from "react";

const FooterLink = ({ href, linkText }) => {
  return (
    <a style={{ margin: " 0px 10px" }} href={href}>
      {linkText}
    </a>
  );
};

export default FooterLink;
