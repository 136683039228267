import React, { useEffect } from "react";
import Router from "next/router";

import { isEmpty } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { reteriveAllDomains } from "../../store/actions/domainActions";
import { reteriveCurrentUser } from "../../store/actions/authActions";

// import {
//   displayNotification
// } from '../../store/actions/notificationActions';

const Gaurd = ({ accessor = "private" }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!localStorage.getItem("accessToken") && accessor == "private") {
      Router.push("/signin?auth_faild=true");
    } else if (isEmpty(user)) {
      dispatch(reteriveAllDomains());
      dispatch(reteriveCurrentUser());
    }
  }, [accessor]);

  // useEffect(() => {
  //   if(!isEmpty(user)) {
  //     if(!user.subscribed) {
  //       Router.push('/subscription')
  //       dispatch(displayNotification({
  //         type: 'error',
  //         message: "Please update your subscription. Redirecting to subscription page."
  //       }));
  //     }
  //   }
  // }, [user]);

  return <></>;
};

export default Gaurd;
