import {
  Badge,
  Divider,
  Drawer,
  Dropdown,
  Layout,
  List,
  Menu,
  Popconfirm,
  Row,
  Switch,
} from "antd";
import {
  VideocamTwoTone,
  DashboardTwoTone,
  HomeTwoTone,
  ComputerTwoTone,
  DescriptionTwoTone,
  PhoneIphoneTwoTone,
  TabletMacTwoTone,
  LayersTwoTone,
  PeopleTwoTone,
  SportsMmaTwoTone,
  TrackChangesTwoTone,
  TodayTwoTone,
  AddCircleTwoTone,
  VpnKeyTwoTone,
  AssessmentTwoTone,
  ScheduleTwoTone,
  DomainTwoTone,
  ShowChartTwoTone,
  VisibilityTwoTone,
  InsertLink,
  DnsTwoTone,
  CompareArrowsTwoTone,
} from "@material-ui/icons";
import { LogOut } from "react-feather";
import { useDispatch, useSelector } from "react-redux";

import DashHeader from "./styles/Header";
import Inner from "./styles/Sidebar";
import Link from "next/link";
import { useAppState } from "./shared/AppProvider";
import { useRouter } from "next/router";
import UserAvatar from "./UserAvatar";
import AllowedForRole from "./Users/AllowedForRole";
import Notifications from "./Notifications/Notifications";
import GeneralStyles from "./GeneralStyles";

const { SubMenu } = Menu;
const { Header, Sider } = Layout;

let rootSubMenuKeys = [];

const getKey = (name, index) => {
  const string = `${name}-${index}`;
  let key = string.replace(" ", "-");
  return key.charAt(0).toLowerCase() + key.slice(1);
};

const UserMenu = (
  <Menu>
    <Menu.Item>Settings</Menu.Item>
    <Menu.Item>Profile</Menu.Item>
    <Menu.Item>Notifications</Menu.Item>
  </Menu>
);

const SidebarContent = ({ sidebarTheme, sidebarMode, collapsed }) => {
  const [state, dispatch] = useAppState();
  const dispatcher = useDispatch();
  const router = useRouter();

  const { pathname, asPath } = router;
  const menuBarToggle = useSelector((state) => state.config.menuBar);

  const logOutUser = () => {
    dispatcher(logoutUser());
  };

  const getIconStyle = (isActive) => {
    return { color: isActive ? "#f3452e" : "#002f53" };
  };

  const menu = (
    <GeneralStyles>
      <Menu
        theme={sidebarTheme}
        className="border-0 scroll-y"
        style={{ flex: 1, height: "100%" }}
        mode={sidebarMode}
      >
        <Menu.Item
          key="homelink"
          className={`link ${pathname === "/" ? "ant-menu-item-selected" : ""}`}
        >
          <Link href="/">
            <a style={getIconStyle(pathname === "/")}>
              <span className="anticon">
                <HomeTwoTone />
              </span>
              <span className="mr-auto" style={{ fontSize: "15px" }}>
                Home
              </span>
            </a>
          </Link>
        </Menu.Item>
        {process.env.whiteLabel && (
          <SubMenu
            key="sessionsSub"
            className="link"
            title={
              <span
                style={getIconStyle(
                  pathname === "/dashboard/activities" ||
                    pathname === "/activities"
                )}
              >
                <span className="anticon">
                  <VideocamTwoTone />
                </span>
                <span style={{ fontSize: "16px" }}>Recordings</span>
              </span>
            }
          >
            <Menu.Item
              key="sessionsDashboard"
              className={`link ${
                pathname === "/dashboard/activities"
                  ? "ant-menu-item-selected"
                  : ""
              }`}
            >
              <Link href="/dashboard/activities">
                <a style={getIconStyle(pathname === "/dashboard/activities")}>
                  <span className="anticon">
                    <DashboardTwoTone />
                  </span>
                  <span className="mr-auto" style={{ fontSize: "16px" }}>
                    Dashboard
                  </span>
                </a>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="sessionslink"
              className={`link ${
                asPath === "/activities" ? "ant-menu-item-selected" : ""
              }`}
            >
              <Link href="/activities">
                <a style={getIconStyle(asPath === "/activities")}>
                  <span className="anticon">
                    <VideocamTwoTone />
                  </span>
                  <span className="mr-auto" style={{ fontSize: "16px" }}>
                    Sessions
                  </span>
                </a>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="sessionsLinkMobile"
              className={`sessionsSubLink link ${
                asPath === "/activities?deviceType=mobile"
                  ? "ant-menu-item-selected"
                  : ""
              }`}
            >
              <Link href="/activities?deviceType=mobile">
                <a
                  style={getIconStyle(
                    asPath === "/activities?deviceType=mobile"
                  )}
                >
                  <span className="anticon">
                    <PhoneIphoneTwoTone />
                  </span>
                  <span className="mr-auto" style={{ fontSize: "16px" }}>
                    Mobile
                  </span>
                </a>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="sessionsLinkDesktop"
              className={`sessionsSubLink link ${
                asPath === "/activities?deviceType=desktop"
                  ? "ant-menu-item-selected"
                  : ""
              }`}
            >
              <Link href="/activities?deviceType=desktop">
                <a
                  style={getIconStyle(
                    asPath === "/activities?deviceType=desktop"
                  )}
                >
                  <span className="anticon">
                    <ComputerTwoTone />
                  </span>
                  <span className="mr-auto" style={{ fontSize: "16px" }}>
                    Desktop
                  </span>
                </a>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="sessionsLinkTablet"
              className={`sessionsSubLink link ${
                asPath === "/activities?deviceType=tablet"
                  ? "ant-menu-item-selected"
                  : ""
              }`}
            >
              <Link href="/activities?deviceType=tablet">
                <a
                  style={getIconStyle(
                    asPath === "/activities?deviceType=tablet"
                  )}
                >
                  <span className="anticon">
                    <TabletMacTwoTone />
                  </span>
                  <span className="mr-auto" style={{ fontSize: "16px" }}>
                    Tablet
                  </span>
                </a>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        <SubMenu
          key="keywordsSub"
          className="link"
          title={
            <span
              style={getIconStyle(
                pathname === "/dashboard/keywords" || pathname === "/keywords"
              )}
            >
              <span className="anticon">
                <VpnKeyTwoTone />
              </span>
              <span style={{ fontSize: "16px" }}>Keywords</span>
            </span>
          }
        >
          <Menu.Item
            key="keywordsDashboard"
            className={`link ${
              pathname === "/dashboard/keywords" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/dashboard/keywords">
              <a style={getIconStyle(pathname === "/dashboard/keywords")}>
                <span className="anticon">
                  <DashboardTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Dashboard
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="keywordslink"
            className={`link ${
              asPath === "/keywords" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/keywords">
              <a style={getIconStyle(asPath === "/keywords")}>
                <span className="anticon">
                  <AssessmentTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Ranking
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="keywordsHistory"
            className={`link ${
              asPath === "/keywords/history" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/keywords/history">
              <a style={getIconStyle(asPath === "/keywords/history")}>
                <span className="anticon">
                  <ScheduleTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  History
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="keywordsComparison"
            className={`link ${
              pathname === "/keywords/comparison"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/keywords/comparison">
              <a style={getIconStyle(pathname === "/keywords/comparison")}>
                <span className="anticon">
                  <CompareArrowsTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Comparison
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="keywordsPages"
            className={`link ${
              pathname === "/keywords/pages" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/keywords/pages">
              <a style={getIconStyle(pathname === "/keywords/pages")}>
                <span className="anticon">
                  <LayersTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Pages
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="keywordsSnapshot"
            className={`link ${
              pathname === "/keywords/snapshot" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/keywords/snapshot">
              <a style={getIconStyle(pathname === "/keywords/snapshot")}>
                <span className="anticon">
                  <VisibilityTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Snapshot
                </span>
              </a>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="competitorsSub"
          className="link"
          title={
            <span
              style={getIconStyle(
                pathname === "/competitors/target" ||
                  pathname === "/competitors/keywords"
              )}
            >
              <span className="anticon">
                <SportsMmaTwoTone />
              </span>
              <span style={{ fontSize: "16px" }}>Competitors</span>
            </span>
          }
        >
          <Menu.Item
            key="targetCompetitors"
            className={`link ${
              pathname === "/competitors/target" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/competitors/target">
              <a style={getIconStyle(pathname === "/competitors/target")}>
                <span className="anticon">
                  <TrackChangesTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Target
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="competitorsKeywords"
            className={`link ${
              asPath === "/competitors/keywords" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/competitors/keywords">
              <a style={getIconStyle(pathname === "/competitors/keywords")}>
                <span className="anticon">
                  <VpnKeyTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Keywords
                </span>
              </a>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="backlinksSub"
          className="link"
          title={
            <span
              style={getIconStyle(
                pathname === "/backlinks/overview" || pathname === "/backlinks"
              )}
            >
              <span className="anticon">
                <ShowChartTwoTone />
              </span>
              <span style={{ fontSize: "16px" }}>Backlinks</span>
            </span>
          }
        >
          <Menu.Item
            key="backlinksOverview"
            className={`link ${
              pathname === "/backlinks/overview" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/backlinks/overview">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/overview")}
                >
                  {<DashboardTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/backlinks/overview"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Dashboard
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="backlinkstab"
            className={`link ${
              pathname === "/backlinks/backlinks"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/backlinks/backlinks">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/backlinks")}
                >
                  {<InsertLink />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/backlinks/backlinks"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Backlinks
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="backlinksSpamScore"
            className={`link ${
              pathname === "/backlinks/backlinksSpamScore"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/backlinks/backlinksSpamScore">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(
                    pathname === "/backlinks/backlinksSpamScore"
                  )}
                >
                  {<DomainTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(
                            pathname === "/backlinks/backlinksSpamScore"
                          ),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Spam Score
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="targetPages"
            className={`link ${
              pathname === "/backlinks/targetPages"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/backlinks/targetPages">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/targetPages")}
                >
                  {<TrackChangesTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(
                            pathname === "/backlinks/targetPages"
                          ),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Target Pages
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="referringDomains"
            className={`link ${
              pathname === "/backlinks/referringDomains"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/backlinks/referringDomains">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(
                    pathname === "/backlinks/referringDomains"
                  )}
                >
                  {<ShowChartTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(
                            pathname === "/backlinks/referringDomains"
                          ),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Top Referring
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="tld"
            className={`link ${
              pathname === "/backlinks/tld" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/backlinks/tld">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/tld")}
                >
                  {<DnsTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/backlinks/tld"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  TLD
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="newandlost"
            className={`link ${
              pathname === "/backlinks/newandlost"
                ? "ant-menu-item-selected"
                : ""
            }`}
          >
            <Link href="/backlinks/newandlost">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/newandlost")}
                >
                  {<ScheduleTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/backlinks/newandlost"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  New and Lost
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="anchors"
            className={`link ${
              pathname === "/backlinks/anchors" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/backlinks/anchors">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/backlinks/anchors")}
                >
                  {<LayersTwoTone />}
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/backlinks/anchors"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Anchors
                </span>
              </a>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="reportsSub"
          className="link"
          title={
            <span style={getIconStyle(pathname.includes("/reports"))}>
              <span className="anticon">
                <DescriptionTwoTone />
              </span>
              <span style={{ fontSize: "16px" }}>Reports</span>
            </span>
          }
        >
          <Menu.Item
            key="reportsBuilder"
            className={`link ${
              pathname === "/reports" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/reports">
              <a style={getIconStyle(pathname === "/reports")}>
                <span className="anticon">
                  <AddCircleTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Builder
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="reportsSchedule"
            className={`link ${
              pathname === "/reports/schedule" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/reports/schedule">
              <a style={getIconStyle(pathname === "/reports/schedule")}>
                <span className="anticon">
                  <TodayTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  Schedule
                </span>
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="reportsHistory"
            className={`link ${
              pathname === "/reports/history" ? "ant-menu-item-selected" : ""
            }`}
          >
            <Link href="/reports/history">
              <a style={getIconStyle(pathname === "/reports/history")}>
                <span className="anticon">
                  <ScheduleTwoTone />
                </span>
                <span className="mr-auto" style={{ fontSize: "16px" }}>
                  History
                </span>
              </a>
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="domainslink"
          className={`link ${
            pathname === "/domains" ? "ant-menu-item-selected" : ""
          }`}
        >
          <Link href="/domains">
            <a>
              <span
                className="anticon"
                style={getIconStyle(pathname === "/domains")}
              >
                <DomainTwoTone />
              </span>
              <span
                className="mr-auto"
                style={
                  !menuBarToggle
                    ? {
                        ...getIconStyle(pathname === "/domains"),
                        fontSize: "16px",
                      }
                    : { fontSize: "16px", color: "white" }
                }
              >
                Domains
              </span>
            </a>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="users"
          className={`link ${
            pathname === "/users" ? "ant-menu-item-selected" : ""
          }`}
        >
          <AllowedForRole roles={["admin"]}>
            <Link href="/users">
              <a>
                <span
                  className="anticon"
                  style={getIconStyle(pathname === "/users")}
                >
                  <PeopleTwoTone />
                </span>
                <span
                  className="mr-auto"
                  style={
                    !menuBarToggle
                      ? {
                          ...getIconStyle(pathname === "/users"),
                          fontSize: "16px",
                        }
                      : { fontSize: "16px", color: "white" }
                  }
                >
                  Users
                </span>
              </a>
            </Link>
          </AllowedForRole>
        </Menu.Item>

        <Menu.Item className="link">
          <Notifications sidebarMenu sideBarCollapse={menuBarToggle} />
        </Menu.Item>
      </Menu>

      <Divider
        className={`m-0`}
        style={{
          display: `${sidebarTheme === "dark" ? "none" : ""}`,
        }}
      />

      <Row
        className="show-on-desktop"
        type="flex"
        align="middle"
        justify="space-around"
      >
        {menuBarToggle ? (
          <img
            src={process.env.whiteLabel ? "/fg_mini_logo.png" : "/logo-m.svg"}
            alt="logo"
            width="18"
            style={{ margin: "10px 0px" }}
          />
        ) : (
          <img
            src={
              process.env.whiteLabel
                ? "/fg_logo.png"
                : state.weakColor
                ? "/logo-lm-white.png"
                : "/logo.svg"
            }
            alt="logo"
            width="100"
            style={{ margin: "10px 0px" }}
          />
        )}
      </Row>

      <div className={`show-on-mobile py-3 px-4 bg-${sidebarTheme}`}>
        <Row type="flex" align="middle" justify="space-around">
          <Dropdown overlay={UserMenu}>
            <span>
              <Badge
                count={6}
                overflowCount={5}
                style={{
                  color: "rgb(245, 106, 0)",
                  backgroundColor: "rgb(253, 227, 207)",
                }}
              >
                <UserAvatar size={40} />
              </Badge>
            </span>
          </Dropdown>
          {!collapsed && (
            <>
              <span className="mr-auto" />
              <Popconfirm
                placement="top"
                title="Are you sure you want to sign out?"
                onConfirm={() => logOutUser()}
                okText="Yes"
                cancelText="Cancel"
              >
                <a
                  className={`px-3 ${
                    sidebarTheme === "dark" ? "text-white" : "text-body"
                  }`}
                >
                  <LogOut size={20} strokeWidth={1} />
                </a>
              </Popconfirm>
            </>
          )}
        </Row>
      </div>
    </GeneralStyles>
  );
  console.log("collapsed", menuBarToggle);
  return (
    <>
      <Inner>
        {!state.mobile && (
          <Sider
            width={200}
            className={`bg-${sidebarTheme} smooth-the-width-stretch`}
            theme={sidebarTheme}
            collapsed={menuBarToggle}
          >
            {menu}
          </Sider>
        )}

        <Drawer
          closable={false}
          width={200}
          placement="left"
          onClose={() => dispatch({ type: "mobileDrawer" })}
          visible={state.mobileDrawer}
          className="chat-drawer"
        >
          <Inner>
            <div
              css={`
                overflow: hidden;
                flex: 1 1 auto;
                flex-direction: column;
                display: flex;
                height: 100vh;
              `}
            >
              <DashHeader>
                <Header>
                  <Link href="/">
                    <a className="brand">
                      <img src="/logo.svg" alt="logo" width="100" />
                      <strong
                        className="mx-1"
                        css={`
                          display: inline;
                        `}
                      ></strong>
                    </a>
                  </Link>
                </Header>
              </DashHeader>
              {menu}
            </div>
          </Inner>
        </Drawer>

        <Drawer
          title="Settings"
          placement="right"
          closable={true}
          width={300}
          onClose={() => dispatch({ type: "options" })}
          visible={state.optionDrawer}
        >
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.boxed}
                onChange={(checked) => dispatch({ type: "boxed" })}
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Boxed view
            </span>
          </List.Item>
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.darkSidebar}
                disabled={state.weakColor}
                onChange={(checked) => dispatch({ type: "sidebarTheme" })}
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Dark sidebar menu
            </span>
          </List.Item>
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.sidebarPopup}
                disabled={state.collapsed}
                onChange={(checked) => dispatch({ type: "sidebarPopup" })}
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Popup sub menus
            </span>
          </List.Item>
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.sidebarIcons}
                disabled={state.collapsed}
                onChange={(checked) => dispatch({ type: "sidebarIcons" })}
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Sidebar menu icons
            </span>
          </List.Item>
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.collapsed}
                onChange={(checked) => dispatch({ type: "collapse" })}
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Collapsed sidebar menu
            </span>
          </List.Item>
          <List.Item
            actions={[
              <Switch
                size="small"
                checked={!!state.weakColor}
                onChange={(checked) =>
                  dispatch({ type: "weak", value: checked })
                }
              />,
            ]}
          >
            <span
              css={`
                -webkit-box-flex: 1;
                -webkit-flex: 1 0;
                -ms-flex: 1 0;
                flex: 1 0;
              `}
            >
              Weak colors
            </span>
          </List.Item>
        </Drawer>
      </Inner>
    </>
  );
};

export default SidebarContent;
