import {
  SET_CURRENT_USER,
  UPDATE_PROFILE,
} from "../actions/types";

const initialState = {
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state["user"],
          ...action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
