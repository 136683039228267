import {
  findIndex,
} from 'lodash';
import {
  SET_ALL_REPORTS,
  SET_NEW_REPORT,
  SET_REPORT_SEARCH,
  DELETE_REPORT
} from "../actions/types";

const initialState = {
  reports: [],
  prevPage: 1,
  nextPage: 1,
  totalPages: 1,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  totalDocs: 0,
  limit: 0,
  search: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_REPORTS:
      const {
        reports, prevPage, nextPage, totalPages, page, hasNextPage, hasPrevPage, totalDocs, limit
      } = action.payload;
      return {
        ...state,
        reports: reports,
          prevPage: prevPage,
          nextPage: nextPage,
          totalPages: totalPages,
          currentPage: page,
          hasNextPage: hasNextPage,
          hasPrevPage: hasPrevPage,
          totalDocs: totalDocs,
          limit: limit
      };
    case SET_REPORT_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_NEW_REPORT:
      return {
        ...state,
        reports: [action.payload, ...state.reports],
      };
    case DELETE_REPORT:
      let clonedReports = [...state.reports];
      const delIndex = findIndex(clonedReports, (report) => {
        return report._id === action.payload;
      });
      clonedReports.splice(delIndex, 1);
      return {
        ...state,
        reports: clonedReports,
      };
    default:
      return {
        ...state,
      };
  }
};