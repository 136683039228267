import {
  SET_PAGES,
  SET_PAGES_SORTER
} from "../actions/types";

const initialState = {
  pages: [],
  sort: {
    'keywords': 1
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGES:
      return {
        ...state,
        pages: action.payload
      };
    case SET_PAGES_SORTER:
      return {
        ...state,
        sort: action.sort
      };
    default:
      return {
        ...state
      };
  }
};