import {
  SET_DOMAINS,
  SET_CURRENT_DOMAIN,
  SET_CREATE_DOMAIN_MODAL
} from "../actions/types";

const initialState = {
  modal: false,
  currentDomain: null,
  domains: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DOMAINS:
      return {
        ...state,
        domains: action.payload,
      };
    case SET_CURRENT_DOMAIN:
      return {
        ...state,
        currentDomain: action.payload,
      };      
      case SET_CREATE_DOMAIN_MODAL:
        return {
          ...state,
          modal: action.payload,
        };      
    default:
      return {
        ...state,
      };
  }
};