import {
  findIndex,
} from 'lodash';
import {
  SET_ALL_USERS,
  SET_NEW_USER,
  SET_USER_SEARCH,
  UPDATE_USER
} from "../actions/types";

const initialState = {
  users: [],
  prevPage: 1,
  nextPage: 1,
  totalPages: 1,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  totalDocs: 0,
  limit: 0,
  search: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_USERS:
      const {
        users, prevPage, nextPage, totalPages, page, hasNextPage, hasPrevPage, totalDocs, limit
      } = action.payload;
      return {
        ...state,
        users: users,
          prevPage: prevPage,
          nextPage: nextPage,
          totalPages: totalPages,
          currentPage: page,
          hasNextPage: hasNextPage,
          hasPrevPage: hasPrevPage,
          totalDocs: totalDocs,
          limit: limit
      };
    case SET_USER_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_NEW_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case UPDATE_USER:
      let clonedUsers = [...state.users];
      const updateIndex = findIndex(clonedUsers, (report) => {
        return report._id === action.payload._id;
      });
      clonedUsers[updateIndex] = action.payload;
      
      return {
        ...state,
        users: clonedUsers,
      };
    default:
      return {
        ...state,
      };
  }
};