import React from "react";
import { Layout } from "antd";

import FooterLink from "./FooterLink";

const { Footer } = Layout;

const SiteFooter = () => {
  return (
    <div>
      <Footer style={{ textAlign: "right" }}>
        LimeClicks ©2021
        <FooterLink href="/" linkText="Home" />
        <FooterLink href="/dashboard/activities" linkText="Dashboard" />
        <FooterLink href="/activities" linkText="Sessions" />
        <FooterLink href="/reports" linkText="Reports" />
        <FooterLink href="/domains" linkText="Domains" />
      </Footer>
    </div>
  );
};

export default SiteFooter;
