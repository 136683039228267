import { DISPLAY_NOTIFICATION } from "../actions/types";

const initialState = {
  notification: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_NOTIFICATION:
      return {
        ...state,
        notification: action.notice,
      };
    default:
      return {
        ...state,
      };
  }
};
