import {
  TOGGLE_DISABLE_BUTTON,
  TOGGLE_MENU_BAR,
  TOGGLE_DOMAIN_CONTROL_MENU
} from "../actions/types";

const initialState = {
  disableButton: false,
  menuBar: false,
  domainControlMenu: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DISABLE_BUTTON:
      return {
        ...state,
        disableButton: action.disable
      };
    case TOGGLE_MENU_BAR:
      return {
        ...state,
        menuBar: action.payload
      };
    case TOGGLE_DOMAIN_CONTROL_MENU:
      return {
        ...state,
        domainControlMenu: action.payload
      };
    default:
      return {
        ...state
      };
  }
};