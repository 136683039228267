import React, { useEffect } from "react";
import { Message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { displayNotification } from "../store/actions/notificationActions";

const Notification = (props) => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);

  const clearNotification = () => dispatch(displayNotification(null));

  const produceNotification = async () => {
    if (notification) {
      switch (notification.type) {
        case "success":
          await Message.success({
            content: notification.message,
            duration: notification.duration || 3,
            style: { fontSize: "18px", marginTop: "10vh" },
            icon: notification.customIcon,
          });
          clearNotification();
          return true;
        case "warning":
          await Message.warning([
            {
              content: notification.message,
              duration: notification.duration || 3,
              style: { fontSize: "18px", color: "yellow", marginTop: "10vh" },
              icon: notification.customIcon,
            },
          ]);
          clearNotification();
          return true;
        case "error":
          await Message.error({
            content: notification.message,
            duration: notification.duration || 3,
            style: { fontSize: "18px", color: "red", marginTop: "10vh" },
            icon: notification.customIcon,
          });
          clearNotification();
          return true;
      }
    }
  };

  useEffect(() => {
    produceNotification();
  }, [notification != null]);

  return <></>;
};

export default Notification;
