import {
  OPEN_KEYWORDS_CREATE_MODAL,
  SET_KEYWORDS,
  SET_KEYWORD,
  SET_KEYWORDS_SEARCH,
  SET_KEYWORDS_SORT,
  ADD_KEYWORD_TO_BE_REMOVED,
  SET_TAGS,
  SET_START_DATE,
  SET_END_DATE,
  SET_COMPARISON_TABLE_DATA,
} from "../actions/types";

const initialState = {
  keyword: null,
  keywords: [],
  prevPage: 1,
  nextPage: 1,
  totalPages: 1,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  totalDocs: 0,
  limit: 0,
  modal: false,
  search: null,
  sort: {
    keyword: 1,
  },
  totalKeywordsCount: 0,
  downKeywordsCount: 0,
  upKeywordsCount: 0,
  noChangeKeywordsCount: 0,
  downImpactKeywordsCount: 0,
  upImpactKeywordsCount: 0,
  removals: [],
  tags: [],
  rankHistory: [],
  startDate: null,
  endDate: null,
  comparisonTableData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_KEYWORDS:
      const {
        keywords,
        prevPage,
        nextPage,
        totalPages,
        page,
        hasNextPage,
        hasPrevPage,
        totalDocs,
        limit,
        totalKeywordsCount,
        downKeywordsCount,
        upKeywordsCount,
        noChangeKeywordsCount,
        downImpactKeywordsCount,
        upImpactKeywordsCount,
        rankHistory,
      } = action.payload;
      return {
        ...state,
        keywords: keywords,
        prevPage: prevPage,
        nextPage: nextPage,
        totalPages: totalPages,
        currentPage: page,
        hasNextPage: hasNextPage,
        hasPrevPage: hasPrevPage,
        totalDocs: totalDocs,
        limit: limit,
        totalKeywordsCount: totalKeywordsCount,
        downKeywordsCount: downKeywordsCount,
        upKeywordsCount: upKeywordsCount,
        noChangeKeywordsCount: noChangeKeywordsCount,
        downImpactKeywordsCount: downImpactKeywordsCount,
        upImpactKeywordsCount: upImpactKeywordsCount,
        rankHistory: rankHistory,
      };
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case OPEN_KEYWORDS_CREATE_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case SET_KEYWORDS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_KEYWORDS_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case ADD_KEYWORD_TO_BE_REMOVED:
      return {
        ...state,
        removals: action.payload,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case SET_COMPARISON_TABLE_DATA:
      return {
        ...state,
        comparisonTableData: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
