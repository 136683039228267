import styled from "styled-components";

const buttonSize = {
  small: "30px",
  medium: "35px",
  large: "40px",
};

const buttonColor = (btnType) => {
  return btnType === "primary" ? "#3e617c" : "#f3452e";
};

const LCButton = styled.button`
  background: ${(props) => buttonColor(props.type)};
  border: 1px solid ${(props) => buttonColor(props.type)};
  margin: ${(props) => (props.addMargin ? "5px" : "0px")};
  color: #ffffff;
  padding: 0.25em 1em;
  border-radius: 8px;
  font-size: 14px;
  width: ${(props) =>
    props.fullWidth ? "-webkit-fill-available" : "fit-content"};
  height: ${(props) => buttonSize[props.size]};

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    box-shadow: 0 6px 10px rgb(0 0 0 / 20%);
  }

  &:disabled {
    cursor: inherit;
    opacity: 0.55;

    &:active,
    &:hover {
      box-shadow: none;
      opacity: 0.55;
    }
  }

  &:focus {
    animation: ${(props) =>
        props.type === "primary" ? "primaryPulse" : "secondaryPulse"}
      0.4s;
    box-shadow: 0 0 0 10px transparent;
  }

  @keyframes primaryPulse {
    0% {
      box-shadow: 0 0 0 0 #3e617c;
    }
  }

  @keyframes secondaryPulse {
    0% {
      box-shadow: 0 0 0 0 #f3452e;
    }
  }
`;

export default LCButton;
