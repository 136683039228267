import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { filter } from "lodash";
import { ExpandMore, ExpandLess, Done } from "@material-ui/icons";

import { useClickOutside } from "../../lib/clickOutside";
import AddDomain from "./AddDomain";
import {
  setCurrentDomain,
  setCurrentDomainFromLocalStorage,
} from "../../store/actions/domainActions";
import { toggleDomainControl } from "../../store/actions/configActions";
import Favicon from "./Favicon";

const DomainControl = (props) => {
  const { Search } = Input;
  const [ref, hasClickedOutside] = useClickOutside();
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [defaultDomainsName, setDefaultDomainsName] = useState("");
  const openMenu = useSelector((state) => state.config.domainControlMenu);
  const domains = useSelector((state) => state.domains.domains);
  const currentDomain = useSelector((state) => state.domains.currentDomain);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentDomain === null) {
      dispatch(setCurrentDomainFromLocalStorage());
    } else {
      setDefaultDomainsName(currentDomain.name);
    }
  }, [currentDomain, defaultDomainsName]);

  useEffect(() => {
    setDomainsOptions(domains);
  }, [domains]);

  useEffect(() => {
    if (hasClickedOutside) {
      dispatch(toggleDomainControl(false));
    }
  }, [hasClickedOutside]);

  const domainSelected = (domain) => {
    setDefaultDomainsName(domain.name);
    dispatch(setCurrentDomain(domain));
    dispatch(toggleDomainControl(false));
  };

  const filterDomains = (event) => {
    const filteredDomains = filter(domains, (domain) => {
      return domain.name.includes(event.target.value);
    });
    setDomainsOptions(filteredDomains);
  };

  const iconStyle = { fontSize: "26px", color: "#002f53" };

  return (
    <div ref={ref}>
      <div
        style={{
          cursor: "pointer",
          color: props.state.darkSidebar ? "#ffffff" : "#002f53",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => dispatch(toggleDomainControl(!openMenu))}
      >
        {currentDomain ? currentDomain.name : "Please select domain."}
        {openMenu ? (
          <ExpandLess fontSize="large" className="ml-3" />
        ) : (
          <ExpandMore fontSize="large" className="ml-3" />
        )}
      </div>
      <div
        className={`domain-controller ${
          openMenu && "domain-controller-open"
        } mt-2`}
      >
        <Search
          placeholder="Search Domain"
          style={{ width: "280px", marginLeft: "10px" }}
          onChange={(event) => filterDomains(event)}
        />
        {domainsOptions && (
          <ul className="domains-list">
            {domainsOptions.map((domainItr) => {
              return (
                <li
                  key={domainItr._id}
                  onClick={() => domainSelected(domainItr)}
                  style={{
                    color:
                      (currentDomain && (domainItr._id === currentDomain._id))
                        ? "#007bff"
                        : "#2e2e2e",
                  }}
                >
                  <span className="mr-2">
                    <Favicon domain={domainItr} />
                  </span>

                  {domainItr.name}
                  {currentDomain && domainItr._id === currentDomain._id && (
                    <span className="ml-2">
                      <Done style={iconStyle} />
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        <AddDomain />
      </div>
    </div>
  );
};

export default DomainControl;
