import styled from "styled-components";

const NotificationStyles = styled.div`
  .notificationLink {
    color: #002f53;
  }

  .notificationLinkText {
    font-size: 16px;
  }

  .drawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
  }

  .title {
    margin-bottom: 0px;
  }

  .buttonsContainer {
    margin-right: 45px;
  }

  .button {
    margin: 0px 5px;
  }
`;

export default NotificationStyles;
