import React, { useState } from "react";
import { Drawer, Switch, Button } from "antd";
import { NotificationsTwoTone, MoreVert, Close } from "@material-ui/icons";

import NotificationItem from "./NotificationItem/NotificationItem";
import NotificationStyles from "./NotificationsStyle";
import LCButton from "../styles/LCButton";

const Notifications = ({ sidebarMenu = false, sideBarCollapse = false }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <NotificationStyles>
      <div
        onClick={() => showDrawer()}
        className={sidebarMenu && "notificationLink"}>
        <span className="anticon notificationLink">
          <NotificationsTwoTone />
        </span>
        <span
          className={sidebarMenu && "notificationLinkText"}
          style={sideBarCollapse ? { color: "white" } : null}>
          Notifications
        </span>
      </div>

      <Drawer
        title={
          <NotificationStyles>
            <div className="drawerHeader">
              <p className="title">Notifications</p>
              <div className="buttonsContainer">
                <Switch className="button" />
                <Button className="button" icon={<MoreVert />} />
              </div>
            </div>
          </NotificationStyles>
        }
        placement="left"
        onClose={onClose}
        visible={visible}
        key="left"
        width={360}
        bodyStyle={{ padding: "0px" }}
        closeIcon={<Button icon={<Close />} />}>
        <NotificationItem
          title="Welcome"
          subTitle="You'll be in good hands with limeclicks professional tracking system and spot."
          timeAgo="1 day ago"
          href="#"
        />
        <NotificationItem
          title="Get Started"
          href="/gettingstarted"
          subTitle="This will enable LimeClicks to capture user behaviour data on your site."
          timeAgo="Now"
        />
      </Drawer>
    </NotificationStyles>
  );
};

export default Notifications;
