import React from "react";
import Link from "next/link";
import { StarTwoTone } from "@material-ui/icons";

import NotificationItemStyles from "./NotificationItemStyles";

const NotificationItem = ({ title, subTitle, timeAgo, href }) => {
  return (
    <NotificationItemStyles>
      <Link href={href}>
        <a className="notificationContainer">
          <div className="notificationInfo">
            <span className="notificationIcon">
              <StarTwoTone />
            </span>
            <span>
              <p className="notificationText notificationTitle">{title}</p>
              <p className="notificationText notificationSubTitle">
                {subTitle}
              </p>
            </span>
          </div>
          <div>
            <p className="notificationText">{timeAgo}</p>
          </div>
        </a>
      </Link>
    </NotificationItemStyles>
  );
};

export default NotificationItem;
