import axios from "axios";
import Router from "next/router";

import getConfig from "next/config";
import { SET_CURRENT_USER } from "./types";
import { displayNotification } from "./notificationActions";
import { setDomains } from "./domainActions";
import { toggleDisableButton } from "./configActions";

export const verifyLogin = (formData) => (dispatch) => {
  axios
    .post(`auth/login`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      dispatch(
        displayNotification({
          type: "success",
          message: "Successfully signed in! Redirecting you to your dashboard.",
        })
      );
      const { accessToken, currentUser, domains } = response.data;
      dispatch(bindToken(accessToken, currentUser, domains, true));
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status) {
        dispatch(
          displayNotification({
            type: "error",
            message: err.response.data.error,
          })
        );
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
    });
};

export const registration = (formData) => (dispatch) => {
  axios
    .post(`auth/register`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      dispatch(
        displayNotification({
          type: "success",
          message: "Successfully signed up!",
        })
      );
      Router.push("/thanks");
      // const {
      //   accessToken,
      //   currentUser,
      //   domains
      // } = response.data;
      // dispatch(bindToken(accessToken, currentUser, domains, true));
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status) {
        if (err.response.data.error === "Duplicate email entered.") {
          dispatch(
            displayNotification({
              type: "error",
              message: "Email is already taken!",
            })
          );
        } else {
          dispatch(
            displayNotification({
              type: "error",
              message: err.response.data.error,
            })
          );
        }
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
    });
};

export const setCurrentUser = (user) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  });
};

export const bindToken =
  (accessToken, currentUser, domains, redirect = false) =>
  (dispatch) => {
    const { publicRuntimeConfig } = getConfig();
    const { baseDomain, environment } = publicRuntimeConfig;
    const defaultOptions = {
      secure: environment === "production",
      domain: baseDomain,
      expires: 14,
    };

    localStorage.setItem("accessToken", accessToken, defaultOptions);

    dispatch(setCurrentUser(currentUser));
    dispatch(setDomains(domains));

    if (redirect) {
      Router.push("/");
    }
  };

export const setDomainCookie = (domains) => (dispatch) => {
  const { publicRuntimeConfig } = getConfig();
  const { baseDomain, environment } = publicRuntimeConfig;
  const defaultOptions = {
    secure: environment === "production",
    domain: baseDomain,
    expires: 14,
  };
  localStorage.setItem("domains", domains, defaultOptions);
  dispatch(setDomains(domains));
};

export const logoutUser = () => (dispatch) => {
  const { publicRuntimeConfig } = getConfig();

  const { baseDomain, environment } = publicRuntimeConfig;

  const defaultOptions = {
    path: "/",
    secure: environment === "production",
    domain: baseDomain,
  };

  localStorage.removeItem("accessToken", defaultOptions);
  dispatch(setCurrentUser(null));
  Router.push("/signin");
};

export const reteriveCurrentUser = () => (dispatch) => {
  axios
    .get(`/auth/me`)
    .then((response) => {
      const user = response.data.user;
      dispatch(setCurrentUser(user));
    })
    .catch((error) => {
      dispatch(
        displayNotification({
          type: "error",
          message: "Unable to reterieve user.",
        })
      );
    });
};

export const forgetPassword = (formData) => (dispatch) => {
  dispatch(toggleDisableButton(true));
  axios
    .post(`auth//forgotpassword`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      dispatch(toggleDisableButton(false));
      dispatch(
        displayNotification({
          type: "success",
          message: "Please check your email for password reset instruction.",
        })
      );
    })
    .catch((err) => {
      dispatch(toggleDisableButton(false));
      if (err.response !== undefined && err.response.status) {
        dispatch(
          displayNotification({
            type: "error",
            message: err.response.data.error,
          })
        );
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
    });
};

export const resetPassword = (resetToken, formData) => (dispatch) => {
  dispatch(toggleDisableButton(true));
  axios
    .put(`auth/resetpassword/${resetToken}`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      dispatch(
        displayNotification({
          type: "success",
          message: "Successfully password reset.",
        })
      );
      const { accessToken, currentUser, domains } = response.data;
      dispatch(bindToken(accessToken, currentUser, domains, true));
      dispatch(toggleDisableButton(false));
    })
    .catch((err) => {
      dispatch(toggleDisableButton(false));
      if (err.response !== undefined && err.response.status) {
        dispatch(
          displayNotification({
            type: "error",
            message: err.response.data.error,
          })
        );
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
    });
};

export const resendConfirmation = (formData) => (dispatch) => {
  dispatch(toggleDisableButton(true));
  axios
    .post(`auth/resendConfirmation`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      dispatch(toggleDisableButton(false));
      dispatch(
        displayNotification({
          type: "success",
          message: "Please check your email for instruction link.",
        })
      );
    })
    .catch((err) => {
      dispatch(toggleDisableButton(false));
      if (err.response !== undefined && err.response.status) {
        dispatch(
          displayNotification({
            type: "error",
            message: err.response.data.error,
          })
        );
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
    });
};

export const confirmation = (token, router) => (dispatch) => {
  dispatch(toggleDisableButton(true));
  axios
    .patch(
      `auth/confirmation`,
      { confirmationToken: token },
      {
        withCredentials: false,
      }
    )
    .then((response) => {
      dispatch(
        displayNotification({
          type: "success",
          message: "Your account is confirmed.",
        })
      );
      router.push("/signin");
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status) {
        dispatch(
          displayNotification({
            type: "error",
            message: err.response.data.error,
          })
        );
      } else {
        dispatch(
          displayNotification({
            type: "error",
            message: "Something went wrong.",
          })
        );
      }
      router.push("/signin");
    });
};
