import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UserAvatar = ({ size }) => {
  const user = useSelector((state) => state.auth.user);
  const [nameFirstLetter, setnameFirstLetter] = useState("");

  useEffect(() => {
    if (user) {
      const jsonParseUser = user.name ? user : JSON.parse(user);
      setnameFirstLetter(`${jsonParseUser.name[0]}${jsonParseUser.name[1]}`);
    }
  }, [user]);
  return (
    <Avatar
      {...(size && { size })}
      style={{ color: "#fff", backgroundColor: "#8DC73D" }}
    >
      {nameFirstLetter}
    </Avatar>
  );
};

export default UserAvatar;
