import { combineReducers } from "redux";
import activityReducer from "./activityReducer";
import analyticsReducer from "./analyticsReducer";
import authReducer from "./authReducer";
import configReducer from "./configReducer";
import domainReducer from "./domainReducer";
import keywordReducer from "./keywordReducer";
import rankReducer from "./rankReducer";
import planReducer from "./planReducer";
import pageReducer from "./pageReducer";
import notificationReducer from "./notificationReducer";
import reportReducer from "./reportReducer";
import userReducer from "./userReducer";
import backlinksReducer from "./backlinksReducer";

export default combineReducers({
  activities: activityReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  config: configReducer,
  domains: domainReducer,
  keywords: keywordReducer,
  ranks: rankReducer,
  notification: notificationReducer,
  plans: planReducer,
  pages: pageReducer,
  reports: reportReducer,
  users: userReducer,
  backlinks: backlinksReducer,
});
