import {
  BACKLINKS_INFO,
  RESET_BACKLINKS,
  SET_BACKLINKS,
  SET_BACKLINKS_DATA,
  SET_CURRENT_BACKLINK,
  SET_DASHBOARD_LOADING,
  SET_FILTERS,
  SHOW_BACKLINK_MODAL,
  VIEW_TYPE,
} from "../actions/types";

const initialState = {
  backlinksSummary: null,
  backlinksSignedUrl: null,
  backlinksNewLost: null,
  backlinks: [],
  showBackLinkModal: false,
  currentBacklink: null,
  dashboardLoading: false,
  referringLinkPlatformTypes : [],
  referringLinkTypes : [],
  tldDistribution : [],
  referringLinkAttributes : [],
  referringLinkCountries : [],
  backlinksNewLost : [],
  referingDomains : [],
  anchorsCount : [],
  targetPagesCount : [],
  viewType : null,
  filters: {
    dofollow: null,
    is_new: null,
    is_lost: null,
    item_type: null,
    tld_from: null,
    domain_from_country: null,
  },
};

const BacklinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKLINKS_DATA:
      const { backlinksSummary, backlinks, backlinksNewLost } = action.payload;
      return {
        ...state,
        backlinksSummary,
        backlinksSignedUrl: backlinks,
        backlinksNewLost,
        dashboardLoading: false,
      };
    case SHOW_BACKLINK_MODAL:
      return {
        ...state,
        showBackLinkModal: action.payload,
      };
    case SET_CURRENT_BACKLINK:
      return {
        ...state,
        currentBacklink: action.payload,
      };
    case SET_BACKLINKS:
      return {
        ...state,
        backlinks: action.payload,
      };
    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        dashboardLoading: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case RESET_BACKLINKS:
      return {
        ...state,
        backlinks: action.payload,
      };
    case BACKLINKS_INFO:
        return {
          ...state, 
          ...action.payload
        }
    case VIEW_TYPE:
        return {
          ...state,
          viewType : action.payload
        }

    default:
      return {
        ...state,
      };
  }
};

export default BacklinksReducer;
